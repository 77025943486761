<template src="./home-bigBangBoom.html"></template>

<script>
export default {
  name: 'home',
  components: {
    PopupTnc: () => import('./snippets/Popup.vue'),
  },
  data() {
    return {
      showTnc: false,
    };
  },
  computed: {},
  destroyed() {},
  methods: {
    goTo(url) {
      this.$store.commit('setUrlVm', url);
      this.$router.push('/soco-vending-machine-event/scan-qr');
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'home';
.bbb-qr {
  background: #000 url('/static/img/big-bang-boom/bg-bbb-store.png') no-repeat center center;
  background-size: cover;
  min-height: calc(100vh - 161px);
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border;
  padding: 0;
  text-align: center;
}
.title {
  margin: 0 auto 100px;
  display: block;
}
.teaser {
  margin: 0 auto 24px;
  display: block;
}
.scan-qr-machines {
  width: 320px;
  height: 320px;
  background: #fff;
  box-shadow: 3px 12px 12px rgba(48, 0, 10, 0.5);
  border-radius: 7px;
  margin: 0 auto 100px;
  font: normal 30px/42px 'brandontext-bold', sans-serif;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.scan-qr-machines img {
  margin: 0 auto;
}
.scan-qr-machines img.arrow {
  position: relative;
  top: -3px;
}
.back {
  font: normal 48px 'brandontext-bold', sans-serif;
  color: #fff;
  margin: 24px 0 0;
  border-bottom: solid 2px #fff;
}
.scan-wait {
  font: normal 25px/35px 'brandontext-bold', sans-serif;
  color: #b32656;
  letter-spacing: 2.5px;
  padding: 24px 0 0;
}
.scan-footers {
  background-color: #b32656;
  padding: 55px 0;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.scan-footers p {
  font-family: 'brandontext-bold', sans-serif;
  font-size: 36px;
  color: #fff;
  text-align: center;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}
</style>
